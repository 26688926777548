/* 테스크탑 (가로 해상도가 1200px 보다 작은 화면에 적용) */
@media screen and (max-width: 1199px) {
  .react-calendar {
    width: 100%;
    background: transparent;
    border: none;
    /* box-shadow: var(--chakra-shadows-lg); */
    padding: var(--chakra-sizes-5);
    border-radius: 20px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: "IBM Plex Sans Kr", Arial, sans-serif;
    line-height: 3em;
  }
}

@media screen and (min-width: 1199px) {
  .react-calendar {
    width: 100%;
    background: transparent;
    border: none;
    /* box-shadow: var(--chakra-shadows-lg); */
    padding: var(--chakra-sizes-5);
    border-radius: 20px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: "IBM Plex Sans Kr", Arial, sans-serif;
    line-height: 3em;
  }
}
/* 태블릿 디바이스 (가로 해상도가 992px 보다 작은 화면에 적용) */
@media screen and (max-width: 991px) {
  .react-calendar {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    /* box-shadow: var(--chakra-shadows-lg); */
    padding: var(--chakra-sizes-5);
    border-radius: 20px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: "IBM Plex Sans Kr", Arial, sans-serif;
    /* line-height: 1.125em; */
    line-height: 0.2em;
    /* line-height: 0.5rem; */
  }
}
/* 세로모드 모바일 디바이스 (가로 해상도가 576px 보다 작은 화면에 적용) */
@media screen and (max-width: 575px) {
  .react-calendar {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    /* box-shadow: var(--chakra-shadows-lg); */
    padding: var(--chakra-sizes-5);
    border-radius: 20px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: "IBM Plex Sans Kr", Arial, sans-serif;
    /* line-height: 1.125em; */
    line-height: 0.1em;
    /* line-height: 0.5rem; */
  }
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: 1em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .react-calendar__navigation {
    /* height: 44px; */
    height: 3em;
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 1300px) {
  .react-calendar__navigation {
    /* height: 44px; */
    height: 3.5em;
    margin-bottom: 1.5em;
    margin-top: 0.7em;
  }
}
@media screen and (min-width: 1200px) {
  .react-calendar__navigation {
    /* height: 44px; */
    height: 3em;
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 991px) {
  .react-calendar__navigation {
    /* height: 44px; */
    height: 3em;
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 575px) {
  .react-calendar__navigation {
    /* height: 44px; */
    height: 2em;
    margin-bottom: 2em;
  }
}
.react-calendar__navigation button {
  color: #ef8216;
  min-width: 44px;
  background: none;
  font-size: 20px;
  font-weight: bold;
  /* margin-top: 5px; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fce694;
  border-radius: 10px;
}
.react-calendar__navigation button[disabled] {
  background-color: transparent;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  /* font-size: 0.75em; */
  font-size: 0.8em;
  margin-bottom: 0.5em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  /* 칸 높이 */
  /* height: 55px; */
  height: 5em;
  /* display: block;
  overflow: auto; */
  /* height: fit-content; */
}
@media screen and (max-width: 575px) {
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.5em 0.5em;
    background: none;
  }
}
.react-calendar__tile:disabled {
  opacity: 0.4;
  pointer-events: none;
  background-color: transparent;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #fce694; */
  background-color: rgb(254, 217, 115);
  border-radius: 10px;
}
.react-calendar__tile--now {
  background: #fbb132;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}
/* .react-calendar__tile--now abbr {
  background: rgb(28, 190, 28);
  color: white;
  border-radius: 50%;
  padding: 17%;
} */
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: #ff8c2d; */
  background: rgba(28, 190, 28, 0.7);
  border-radius: 10px;
  font-weight: bold;
}
/* .react-calendar__tile--hasActive {
  background: rgba(28, 190, 28, 0.7);
} */
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /* background: #ff8c2d; */
  background: rgba(28, 190, 28, 0.7);
  border-radius: 10px;
  font-weight: bold;
}
.react-calendar__tile--active {
  /* background: #ff8c2d; */
  background: rgba(28, 190, 28, 0.7);
  border-radius: 10px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* background-color: #ff682d; */
  background: rgb(28, 190, 28);
  /* background: rgb(50, 199, 50); */
  border-radius: 10px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgb(254, 217, 115);
}
.mark abbr {
  background-color: #ff8c2d;
  color: white;
  border-radius: 50%;
  padding: 13%;
}
@media screen and (max-width: 575px) {
  .mark abbr {
    background-color: #ff8c2d;
    color: white;
    border-radius: 50%;
    padding: 10%;
  }
}
